<template>
  <div class="f-home-box">
    <div class="header-box">
      <div style="width: 100%;" v-if="scrollTopVal > 0"></div>
      <div :class="['header', scrollTopVal > 0 ? 'scrollTopFixed' : '']">
        <div class="logoBox">
          <img src="../../assets/new/logo.jpg" class="logo-img" alt="">
          <div class="logo-name">易助粒担保</div>
        </div>

        <div class="header-left">
          <div class="menu-style">
            <a-menu v-model="currents" mode="horizontal">
              <a-menu-item key="topBox" @click="navbar({name:'首页'})" class="menu-item-box">首页
                <img class="menu-title-bg" src="../../assets/new/title-bg.png" alt="">
              </a-menu-item>
              <!-- <a-menu-item key="mainBusiness" @click="navbar({name:'主营业务'})">主营业务</a-menu-item>
              <a-menu-item key="advantage" @click="navbar({name:'平台优势'})">平台优势</a-menu-item>
              <a-menu-item key="products" @click="navbar({name:'金彩贷'})">金彩贷</a-menu-item> -->
              <a-menu-item key="aboutUs" @click="navbar({name:'关于我们'})">关于我们</a-menu-item>
              <!--              <a-menu-item key="news" @click="navbar({name:'新闻动态'})">新闻动态</a-menu-item>-->
              <!-- <a-menu-item key="foot" @click="navbar({name:'联系我们'})">联系我们</a-menu-item> -->
            </a-menu>
          </div>
        </div>
      </div>
    </div>
    <!-- 首页 -->
    <div class="topBox" ref="topBox">
      <img src="../../assets/new/index-bg.png" class="topBox-bg" alt="">
      <div class="topBanner">
        <div>
          <div class="title-box">
            <img class="jcdImg" src="@/assets/new/logo.jpg" alt="">
            <div class="title-text">易助粒担保致力于为小微型企业、个体商户等客户提供专业的融资担保服务。</div>
          </div>
          <div class="top-sub-title">最高可担保额度（元）</div>
          <div class="top-money">
            <div class="top-money-num">¥200,000</div>
            <div class="top-money-bg"></div>
          </div>
        </div>
        <div>
        </div>
      </div>
      <div class="top-btm">
        <div class="top-btm-item">
          <img src="../../assets/new/1.png" alt="">
          <div class="top-btm-item-rt">
            <p>额度高</p>
            <p>最高20万</p>
          </div>
        </div>
        <div class="top-btm-item top-btm-item2">
          <img src="../../assets/new/2.png" alt="">
          <div class="top-btm-item-rt">
            <p>期限灵活</p>
            <p>6-60期选择多</p>
          </div>
        </div>
        <div class="top-btm-item">
          <img src="../../assets/new/3.png" alt="">
          <div class="top-btm-item-rt">
            <p>审批快</p>
            <p>最快15分钟出额度</p>
          </div>

        </div>
      </div>
    </div>
    <!-- 申请流程 -->
    <div class="apply">
      <div class="apply-title">
        <p>申请流程</p>
        <img src="../../assets/new/title-bg.png" alt="">
        <div class="apply-line"></div>
      </div>
      <div class="apply-box">
        <div class="apply-item">
          <img src="../../assets/new/4.png" class="apply-item-img" alt="">
          <div class="apply-item-text">
            <p>线上申请</p>
            <p>操作简单</p>
            <p>申请流程在线完成</p>
          </div>
          <img src="../../assets/new/41.png" class="apply-item-num" alt="">
        </div>
        <div class="apply-item">
          <img src="../../assets/new/5.png" class="apply-item-img apply-item-img2" alt="">
          <div class="apply-item-text">
            <p>线上申请</p>
            <p>操作简单</p>
            <p>申请流程在线完成</p>
          </div>
          <img src="../../assets/new/51.png" class="apply-item-num" alt="">
        </div>
        <div class="apply-item">
          <img src="../../assets/new/6.png" class="apply-item-img apply-item-img3" alt="">
          <div class="apply-item-text">
            <p>线上申请</p>
            <p>操作简单</p>
            <p>申请流程在线完成</p>
          </div>
          <img src="../../assets/new/61.png" class="apply-item-num" alt="">
        </div>
        <div class="apply-item">
          <img src="../../assets/new/7.png" class="apply-item-img apply-item-img4" alt="">
          <div class="apply-item-text">
            <p>线上申请</p>
            <p>操作简单</p>
            <p>申请流程在线完成</p>
          </div>
          <img src="../../assets/new/71.png" class="apply-item-num" alt="">
        </div>

      </div>
    </div>

    <!--    关于我们-->
    <div class="about-box">
      <div class="aboutUs" ref="aboutUs">
        <div class="about-title">
          <p>关于易助粒担保</p>
          <img src="../../assets/new/title-bg.png" alt="">
        </div>
        <img class="about-us-img" src="../../assets/new/about-us.png" alt="">
      </div>
    </div>
    <!-- 公司 -->
    <div class="company">
      <div class="company-box">
        <div class="company-lt">
          <div class="company-img"></div>
          <p class="company-p1">公司名称：</p>
          <p class="company-p2">内蒙古禄盛通融资担保有限责任公司</p>
        </div>
        <div class="company-lt company-rt">
          <div class="company-img"></div>
          <p class="company-p1">联系电话：</p>
          <p class="company-p2">17616333737</p>
          <!-- <p class="company-p3">周一至周日9:00-21:00</p> -->

        </div>
      </div>
    </div>

    <div class="address">
      <!-- <img class="addressImg" src="https://oss.itcait.com/jincdev/webImg/address.png" alt=""> -->
      <div class="addressName"><a href="https://beian.miit.gov.cn/">ICP备案：蒙ICP备2024019291</a></div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTopVal: 0,
      showItem: true,
      showTop: false,
      showRightTab: false,
      currents: ["topBox"],
      currentResolution: "",
    };
  },

  mounted() {
    this.setCurrentResolution();
    window.addEventListener("resize", this.setCurrentResolution);
    this.$nextTick(() => {
      // 开启滚动条监听
      window.addEventListener("scroll", this.scrollTop, true);
    });
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollTop, true);
    window.removeEventListener("resize", this.setCurrentResolution);
  },

  methods: {
    setCurrentResolution() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width <= 1920 && height <= 1080) {
        this.currentResolution = "mobile";
      }
      if (width >= 1920 && height > 1080) {
        this.currentResolution = "tablet";
      }
    },

    // 鼠标移入
    mouseenter() {
      this.showItem = true;
      this.showRightTab = true;
    },

    // 鼠标移出
    mouseleave() {
      this.showItem = false;
      this.showRightTab = false;
    },

    // 控制右侧导航栏的显示隐藏
    scrollTop() {
      this.showItem = true;
      let elVal =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      let top = Math.floor(elVal);
      this.scrollTopVal = top;
      console.log(elVal, "elVal");
      if (this.currentResolution == "tablet") {
        if (elVal < 1190) {
          this.currents = ["topBox"];
        } else if (elVal >= 1200 && elVal < 1950) {
          // this.showTop = true
          this.currents = ["mainBusiness"];
        } else if (elVal >= 1990 && elVal < 2490) {
          // this.showTop = true
          this.currents = ["advantage"];
        } else if (elVal >= 2500 && elVal < 3340) {
          this.currents = ["products"];
          // this.showTop = false
          // this.showRightTab = false
        } else if (elVal >= 3350 && elVal < 3740) {
          this.currents = ["aboutUs"];
        } else if (elVal >= 3750) {
          this.currents = ["foot"];
        }
      } else if (this.currentResolution == "mobile") {
        if (elVal < 900) {
          this.currents = ["topBox"];
        } else if (elVal >= 900 && elVal < 1670) {
          // this.showTop = true
          this.currents = ["mainBusiness"];
        } else if (elVal >= 1670 && elVal < 2140) {
          // this.showTop = true
          this.currents = ["advantage"];
        } else if (elVal >= 2150 && elVal < 3040) {
          this.currents = ["products"];
          // this.showTop = false
          // this.showRightTab = false
        } else if (elVal >= 3050 && elVal < 3775) {
          this.currents = ["aboutUs"];
        } else if (elVal >= 3785) {
          this.currents = ["foot"];
        }
      }
    },

    navbar(tab, index) {
      // 根据点击的选项卡值来滚动到相应的卡片位置
      switch (tab.name) {
        case "首页":
          this.scrollToCard("topBox");
          // this.showTop = false
          // this.currents = ['topBox']
          break;
        case "关于我们":
          this.scrollToCard("aboutUs");
          // this.showTop = true
          // setTimeout(() => {
          //   this.showItem = false
          //   this.showRightTab = false
          // }, 3000)
          break;
        case "主营业务":
          this.scrollToCard("mainBusiness");
          // this.showTop = true
          // setTimeout(() => {
          //   this.showItem = false
          //   this.showRightTab = false
          // }, 3000)
          break;
        case "平台优势":
          this.scrollToCard("advantage");
          // this.showTop = true
          // setTimeout(() => {
          //   this.showItem = false
          //   this.showRightTab = false
          // }, 3000)
          break;
        case "金彩贷":
          this.scrollToCard("products");
          // this.showTop = true
          // setTimeout(() => {
          //   this.showItem = false
          //   this.showRightTab = false
          // }, 3000)
          break;
        case "新闻动态":
          // this.scrollToCard('resourceQuery');
          break;
        case "联系我们":
          this.scrollToCard("foot");
          break;
        //你可以在这里添加新的想定位到达的部分
      }
    },

    scrollToCard(refName) {
      // 使用 Vue 的 $refs 来获取卡片元素，并滚动到其位置
      const cardElement = this.$refs[refName];
      if (cardElement) {
        // 使用原生的 scrollIntoView 方法来滚动到元素位置
        cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.f-home-box {
  width: 100%;

  .header-box {
    width: 100%;
    position: absolute;
    z-index: 999;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 8px;
      color: #fff;
      transition: all 1s;

      .logoBox {
        display: flex;
        align-items: center;

        .logo-img {
          width: 60px;
          height: 60px;
        }

        .logo-name {
          font-size: 16px;
          font-weight: 500;
          color: rgb(45, 100, 237);
          margin-left: 12px;
          width: 250px;
        }
      }

      .header-left {
        display: flex;
        align-items: center;

        .menu-style {
          margin-left: 88px;

          .menu-item-box {
            position: relative;
            .menu-title-bg {
              position: absolute;
              width: 16px;
              height: 16px;
              top: 25px;
              left: 35px;
              z-index: -1;
              opacity: 0.4;
            }
          }
        }
      }

      .ant-menu-horizontal {
        line-height: 80px !important;
        font-size: 16px;
        border-bottom: 1px solid transparent;
        background: transparent;
        color: #333;

        .ant-menu-item-selected,
        .ant-menu-submenu-selected,
        .ant-menu-submenu-open,
        .ant-menu-item-open,
        .ant-menu-submenu-active .ant-menu-item-active,
        .ant-menu-submenu:hover,
        .ant-menu-item:hover {
          font-size: 16px;
          color: #333;
          border-bottom: 1px solid rgb(45, 100, 237);
          // font-weight: bold;
        }

        // .ant-sub-menu-active>.ant-menu-item-active {
        //   font-size: 18px;
        //   color: #fff;
        //   border-bottom: 2px solid #fff;
        //   font-weight: bold;
        // }
      }

      .ant-menu-item,
      .ant-menu-submenu-title {
        padding: 0 42px;
      }

      .ant-input {
        height: 47px;
        font-size: 17px;
      }

      .ant-input-affix-wrapper {
        font-size: 23px;
      }
    }

    .scrollTopFixed {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(20px);
      z-index: 9;
      box-shadow: 0px 1px 3px #fff;
      color: #000;

      .ant-menu-horizontal {
        line-height: 80px !important;
        font-size: 16px;
        border-bottom: 1px solid transparent;
        background: transparent;
        color: #000;

        .ant-menu-item-selected,
        .ant-menu-submenu-selected,
        .ant-menu-submenu-open,
        .ant-menu-item-open,
        .ant-menu-submenu-active,
        .ant-menu-item-active,
        .ant-menu-submenu:hover,
        .ant-menu-item:hover {
          font-size: 16px;
          color: rgb(45, 100, 237);
          border-bottom: 1px solid rgb(45, 100, 237);
          // font-weight: bold;
        }
      }

      .logoBox > .logo-name {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin-left: 12px;
      }
    }
  }
  // 首页
  .topBox {
    width: 100%;
    position: relative;
    background: linear-gradient(279deg, #f2f5fb, #f1f6fb);
    padding-bottom: 32px;
    .topBox-bg {
      position: absolute;
      right: 0;
      height: 100%;
    }

    .topBanner {
      width: 1200px;
      height: 570px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .title-box {
        display: flex;
        width: 800px;
        margin-bottom: 35px;
        margin-top: 160px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          position: relative;
          top: -25px;
        }
        .title-text {
          color: #666;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .top-sub-title {
        color: #333;
        font-family: PingFangSC-Light, PingFang SC;
        font-size: 36px;
        line-height: 50px;
        margin-bottom: 42px;
      }
      .top-money {
        color: #333;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 100px;
        line-height: 100px;
        position: relative;
        .top-money-num {
          position: relative;
          z-index: 2;
        }
        .top-money-bg {
          position: absolute;
          bottom: -18px;
          width: 430px;
          height: 50px;
          background-image: url("../../assets/new/num-bg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .top-btm {
      width: 1200px;
      height: 90px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      background: #fff;
      box-shadow: 0 6px 16px 0 #dae3ee;
      .top-btm-item {
        width: 400px;
        height: 49px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 44px;
          height: 37px;
          margin-right: 34px;
        }
        .top-btm-item-rt p:nth-child(1) {
          color: #333;
          font-family: PingFangSC-Light, PingFang SC;
          font-size: 21px;
          font-weight: 300;
          position: relative;
          top: 15px;
        }
        .top-btm-item-rt p:nth-child(2) {
          color: #8a8172;
          font-family: PingFangSC-Light, PingFang SC;
          // font-size: 14px;
          font-weight: 300;
          position: relative;
          top: -5px;
        }
      }
      .top-btm-item2 {
        border-left: 1px solid rgb(211, 215, 227);
        border-right: 1px solid rgb(211, 215, 227);
      }
    }

    .jcdImg {
      width: 284px;
      height: 76px;
      margin-top: 27px;
    }

    .titleFont {
      color: rgba(255, 255, 255, 0.84);
      font-size: 50px;
      font-weight: 500;
      line-height: 65px;
      letter-spacing: 0px;
      text-align: right;
      text-transform: uppercase;
    }

    .position {
      //display: flex;
      //justify-content: end;
      cursor: pointer;
      position: relative;

      .aboutus {
        width: 220px;
        border-radius: 10px;
        /* 渐变 */
        background: linear-gradient(
          131.26deg,
          rgb(253, 169, 51) -13.511%,
          rgba(253, 232, 51, 0.93) 100%
        );
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0px;
        text-align: center;
        text-transform: uppercase;
        padding: 7px 0;
        margin-top: 77px;
        position: absolute;
        right: 0;
      }
    }
  }
  // 申请流程
  .apply {
    width: 1200px;
    margin: 0 auto;
    .apply-title {
      color: #333;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-size: 36px;
      font-weight: 600;
      line-height: 36px;
      width: 180px;
      text-align: center;
      margin: 0 auto;
      margin-top: 67px;
      position: relative;
      .apply-line {
        background-color: rgb(45, 100, 237);
        bottom: -20px;
        height: 4px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 30px;
      }
      img {
        position: absolute;
        top: -5px;
        left: 5px;
        z-index: -1;
        opacity: 0.4;
      }
    }
    .apply-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .apply-item {
        // width: 600px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 115px;
        .apply-item-img {
          width: 295px;
          height: 177px;
          margin-right: 27px;
        }
        .apply-item-img2 {
          width: 258px;
          height: 209px;
          margin-right: 55px;
        }
        .apply-item-img3 {
          width: 290px;
          height: 176px;
          margin-right: 38px;
        }
        .apply-item-img4 {
          width: 262px;
          height: 195px;
          margin-right: 55px;
        }
        .apply-item-text p:nth-child(1) {
          color: #333;
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 28px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 18px;
        }
        .apply-item-text p:nth-child(2) {
          color: #333;
          font-family: PingFangSC-Light, PingFang SC;
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          margin-bottom: 11px;
        }
        .apply-item-text p:nth-child(3) {
          color: #333;
          font-family: PingFangSC-Light, PingFang SC;
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
        }
        .apply-item-num {
          position: absolute;
          width: 77px;
          height: 90px;
          bottom: 40px;
          right: 35px;
        }
      }
    }
  }

  .about-box {
    // background: url("../../assets/new/about-bg.png") no-repeat;

    background: no-repeat 50% / auto 748px url("../../assets/new/about-bg.png"),
      linear-gradient(279deg, #f2f5fb, #f1f6fb);
    background-size: 100% 100%;
    height: 748px;
    .aboutUs {
      width: 1200px;
      padding: 110px 0 70px;
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: space-between;
      .about-title {
        position: relative;

        p {
          color: #333;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-size: 36px;
          font-weight: 600;
        }
        img {
          position: absolute;
          width: 36px;
          height: 36px;
          top: 0;
          left: -10px;
          z-index: -1;
        }
      }
      .about-us-img {
        width: 251px;
        height: 542px;
        position: relative;
        right: 200px;
      }
    }
  }
  .company {
    .company-box {
      width: 1200px;
      height: 350px;
      padding-top: 80px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .company-lt {
        .company-img {
          width: 26px;
          height: 26px;
          margin-bottom: 25px;
          background: url("../../assets/new/company1.png") no-repeat;
          background-size: 100% 100%;
        }
        .company-p1 {
          color: #283662;
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          margin-bottom: 30px;
        }
        .company-p2,
        .company-p3 {
          color: #333;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-size: 24px;
          font-weight: 600;
          line-height: 21px;
          margin-bottom: 30px;
        }
      }
      .company-rt {
        .company-img {
          width: 26px;
          height: 26px;
          margin-bottom: 25px;
          background: url("../../assets/new/company2.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .address {
    width: 100%;
    padding: 40px 0 34px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    .addressImg {
      width: 26px;
      height: 24px;
    }

    .addressName {
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0px;
      text-align: right;
      text-transform: uppercase;
      margin-left: 20px;
      a {
      }
    }
  }
}
</style>
